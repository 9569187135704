<template>
	<div>
		<!--
		<div>
			<a-list item-layout="horizontal" :data-source="sortedArray" style=" padding: 2em 10em">
				<a-list-item v-if="item.szoveg.substring(0, 4) == 'http' && item.lathato == true" slot="renderItem" slot-scope="item">
					<a-list-item-meta :description="text">
						<p slot="title">{{ item.cim }}</p>
					</a-list-item-meta>
					<a-button class="ant-btn ant-btn-primary" type="primary">
						<a :href="item.szoveg">Tovább</a>
					</a-button>
				</a-list-item>
				<a-list-item
					v-else-if="item.szoveg.substring(0, 4) != 'http' && item.lathato == true"
					slot="renderItem"
					slot-scope="item"
				>
					<a-list-item-meta :description="item.szoveg">
						<p slot="title">{{ item.cim }}</p>
					</a-list-item-meta>
				</a-list-item>
			</a-list>
		</div>
	

		<div v-for="news in sortedArray" :key="news.kod">
			<div class="cards">
				<div class="card" v-if="news.lathato == true">
					<div class="card-body">
						<p class="card-front">
							{{ news.cim }}
						</p>
						<a-icon class="frontIcon" type="rollback" />
						<div v-if="news.szoveg.substring(0, 4) != 'http'" class="card-back">
							<p v-html="news.szoveg"></p>
						</div>

						<div v-else-if="news.szoveg.substring(0, 4) == 'http'" class="card-back">
							<p>
								{{ text }}
							</p>
							<a-button class="ant-btn ant-btn-primary" type="primary">
								<a :href="news.szoveg">Tovább</a>
							</a-button>
						</div>
					</div>
				</div>
			</div>
		</div>
-->
		<div v-for="news in sortedArray" :key="news.kod">
			<div
				v-if="news.lathato == true"
				class="flipcard"
				ref="input"
				@click="activateFlip = news.kod"
				:class="{ flipped: activateFlip === news.kod }"
			>
				<div class="face front">
					<p class="date">{{ news.datum }}</p>
					<p>
						{{ news.cim }}
					</p>
					<a-icon class="frontIcon" type="rollback" />
				</div>

				<div v-if="news.szoveg.substring(0, 4) != 'http'" class="face back">
					<p v-html="news.szoveg"></p>
				</div>
				<div v-else-if="news.szoveg.substring(0, 4) == 'http'" class="face back">
					<p>
						{{ text }}
					</p>
					<a-button class="ant-btn ant-btn-primary" type="primary">
						<a :href="news.szoveg">Tovább</a>
					</a-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			text:
				'A szolgáltatás ellátása során kiemelt figyelmet kap társaságunk ügyfeleinek,partnereinek elégedettség vizsgálata, ezért kérjük, töltse ki az ügyfél elégedettségi kérdőívet.',
			isActive: false,
			activateFlip: '',
		};
	},
	created() {
		this.$store.dispatch('getNewsList');
	},

	computed: {
		...mapState({ newsList: (state) => state.auth.newsList }),
		sortedArray() {
			const sortedArray = this.newsList;

			return sortedArray.sort((a, b) => a.datum - b.datum);
		},
	},
	methods: {
		// activateFlip() {
		// 	this.isActive = !this.isActive;
		// },
	},
};
</script>

<style lang="scss">
@import '@/styles/_global.scss';
</style>

<style lang="scss" scoped>
.flipcard .front p {
	color: #1f4e8d;
}
.flipcard {
	padding: 1em 10em;
	perspective: 40em;
	transform-style: preserve-3d;
	transition: 0.5s;
	display: flex;
	cursor: pointer;
}

.flipcard.flipped {
	transform: rotateX(-180deg);
	transition: 0.5s transform;
}
.flipcard .face {
	backface-visibility: hidden;
	min-width: 100%;
	overflow: hidden;
	padding: 1em;
	border-radius: 0.25rem;
}
.flipcard .front {
	font-size: 1.5rem;
	background-color: white;
	display: flex;
	justify-content: center;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flipcard.flipped .front {
	display: none;
}
.flipcard .back {
	background-color: #1f4e8d;
	font-size: 1.25em;
	color: white;
	transform: translateX(0%) rotateX(-180deg);
	transition: 0.6s transform;

	display: none;
}
.flipcard.flipped .back {
	display: block;
}

/deep/ p {
	margin-top: 0;
	margin-bottom: 0;
}

/deep/ .flipcard.flipped .back > p > a {
	color: #92cbc5 !important;
}

.date {
	position: absolute;
	left: 8em;
	top: 0.75em;
	font-size: 0.75em;
}

.frontIcon {
	position: absolute;
	right: 4em;
	top: 0.5em;
	font-size: 1.5em;
	color: #1f4e8d;
}

.cards {
	display: grid;
	grid-gap: 1.5rem;
	grid-auto-rows: 1fr;
	padding: 1rem 10em;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}

.card {
	perspective: 40rem;
	transition: z-index, transform calc(0.6s / 4);
	transition-delay: 0.6s, 0s;
	text-decoration: none;
	color: inherit;
	z-index: 0;

	&:hover {
		transition-delay: 0s;
		z-index: 1;
	}

	&:active {
		transform: scale(0.975);
	}
}

.card-body {
	border-radius: 0.25rem;
	flex: 1;
	display: flex;
	transform-style: preserve-3d;
	transition: 0.6s transform;
	.card:hover &,
	.card:focus & {
		transform: rotateX(-180deg);
	}
}

.card-front,
.card-back {
	backface-visibility: hidden;
	min-width: 100%;
	justify-content: center;
	margin: 0;
	overflow: hidden;
}

.card-front {
	font-size: 1.5rem;
	@extend .shadow;
	display: flex;
	align-items: center;
	background-color: white;
	box-sizing: border-box;
	padding: 1.5rem;
	border-radius: 0.25rem;
}

.card-back {
	@extend .shadow;
	display: flex;
	align-items: center;
	background-color: #1f4e8d !important;
	color: white;
	box-sizing: border-box;
	padding: 1.5rem;
	border-radius: 0.25rem;
	transform: rotateX(-180deg) translate(-100%, 0);
}

/deep/ .card-back > p > a {
	color: #92cbc5 !important;
}

.card-back > p {
	margin: 0;
	font-size: 1.25em;
}

.card-back > button {
	margin-left: 1em;
}

.shadow {
	box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04),
		0 8px 8px rgba(0, 0, 0, 0.04), 0 16px 16px rgba(0, 0, 0, 0.04);
}
.card-back > p {
	overflow: hidden;
}

@media only screen and (max-width: 1200px) {
	.flipcard .front > p {
		font-size: 1rem;
	}

	.flipcard .back {
		font-size: 1rem;
	}
	.date {
		position: absolute;
		left: 9em;
		top: 0.75em;
	}
	.frontIcon {
		position: absolute;
		right: 6em;
		top: 0.5em;
		font-size: 1em;
	}
	.card-back > p {
		overflow: hidden;
	}
	.card-back > p {
		font-size: 1em;
	}
	.card-front,
	.card-back {
		font-size: 1em;
	}

	.cards {
		padding: 1rem 1em;
	}

	.card {
		display: block;
	}
}

@media only screen and (max-width: 900px) {
	.flipcard {
		padding: 1em 5em;
	}

	.date {
		position: absolute;
		left: 5em;
		top: 0.75em;
	}

	.frontIcon {
		position: absolute;
		right: 3em;
		top: 0.5em;
		font-size: 1em;
	}
}

@media only screen and (max-width: 485px) {
	.cards {
		grid-template-columns: repeat(auto-fit, minmax(17em, 2em));
	}

	.flipcard {
		padding: 1em 1em;
	}

	.date {
		position: absolute;
		left: 1em;
		top: 0.75em;
	}

	.frontIcon {
		position: absolute;
		right: 0.5em;
		top: 0.5em;
		font-size: 1em;
	}
}

@media only screen and (max-width: 485px) {
	.cards {
		grid-template-columns: repeat(auto-fit, minmax(17em, 2em));
	}
}
@media only screen and (max-width: 400px) {
	.cards {
		grid-template-columns: repeat(auto-fit, minmax(14em, 2em));
	}
}
@media only screen and (max-width: 360px) {
	.cards {
		grid-template-columns: repeat(auto-fit, minmax(13em, 2em));
	}
}
</style>
